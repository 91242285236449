<template>
  <div class="personalizacion-intranet">
    <div class="personalizacion-intranet__container">
      <div class="personalizacion-intranet__top d-flex justify-content-between">
        <ul class="personalizacion-intranet__links-wrapper d-flex">
          <li>
            <router-link class="personalizacion-intranet__link me-5" :class="$route.name == 'interfaz-admin' ? 'onhover':''" :to="{name: 'interfaz-admin'}"
              >Interfaz</router-link
            >
          </li>
          <li>
            <router-link class="personalizacion-intranet__link me-5" :class="$route.name == 'editar-admin' ? 'onhover':''"  :to="{name: 'editar-admin'}"
              >Editar</router-link
            >
          </li>
          <li>
            <router-link class="personalizacion-intranet__link me-5" :class="$router.name == 'organizar-admin' ? 'onhover':''" :to="{name: 'organizar-admin'}"
              >Organizar</router-link
            >
          </li>
          <li>
            <router-link class="personalizacion-intranet__link me-5" :class="$router.name == 'menu-admin' ? 'onhover':''" :to="{name: 'menu-admin'}"
              >Menú</router-link
            >
          </li>
        </ul>
      </div>
      <div class="personalizacion-intranet__bottom">
        <router-view></router-view>
      </div>
      <div class="d-flex justify-content-end mt-3">
        <button class="personalizacion-intranet__save-button">
          Guardar ajustes
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu: 0,
      info: false,
      links: ["Interfaz", "Editar", "Organizar", "Menu"],
      show_component: 0,
    };
  },
  methods: {
    selectComponent(i_) {
      this.show_component = i_;
    },
    checkRouterName(name_) {
      console.log('name', this.$route.name);
      let class_ = ''
      if (this.$route.name == name_) class_ == 'onhover';
      return class_;
    }
  },
};
</script>
